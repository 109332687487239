import { Box, IconButton, List, ListItem, ListItemProps, ListItemText, SxProps, Typography } from "@mui/material";
import { ReactElement, ReactNode, useState } from "react";
import colors from "../../colors";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { CabDropdown } from "@CabComponents/CabDropdown";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import { CabAutocomplete } from "@CabComponents/CabAutocomplete";
import { CabIcon } from "@CabComponents/CabIcon";
import { BsDot } from "react-icons/bs";
import { IoAdd, IoOpenOutline, IoTrashOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { CabButton } from "@CabComponents/CabButton";
import { useFieldArray, useForm } from "react-hook-form";
import { validateEmail } from "../../utils/inputUtils";
import { FormController } from "../../utils/formUtils";

interface WidgetProps {
  header?: string;
  action?: ReactNode;
  children: ReactNode;
  sx?: SxProps;
}

export const Widget = ({ header, action, children, sx }: WidgetProps): ReactElement => (
  <Box
    border={`1px solid ${colors.black400}`} borderRadius={2}
    padding={1} paddingTop={0} paddingBottom={0}
    width='100%' overflow="hidden" sx={sx}
  >
    <Box display="flex" justifyContent="space-between">
      {header && (
        <Typography variant="h2" fontSize={18} paddingTop={2} paddingLeft={1} paddingBottom={2}>
          {header}
        </Typography>
      )}
      {action}
    </Box>
    {children}
  </Box>
);

export const WidgetList = ({ children }: { children: ReactNode }) => (
  <List disablePadding sx={{ paddingLeft: 1, paddingRight: 1 }}>
    {children}
  </List>
);

export const WidgetListItem = ({ onClick, onBlur, secondaryAction, children }: {
  onClick?: () => void;
  onBlur?: () => void;
  secondaryAction?: ListItemProps['secondaryAction'];
  children: ReactNode;
}) => (
  <ListItem
    divider
    disableGutters
    secondaryAction={secondaryAction}
    onClick={onClick}
    onBlur={onBlur}
    sx={{ cursor: onClick ? 'pointer' : undefined }}
  >
    {children}
  </ListItem>
);

export interface BasicInfoListItemProps {
  title: string;
  text?: string | null;
  inputType: 'text'|'select'|'multiline'|'autocomplete'|'list';
  inputValue: string | number | number[] | string[] | null;
  onSave: (val: string | number | number[] | string[] | (string | number)[] | null) => void;
  color?: string; 
  selectOptions?: { value: string|number, label: string, color?: string }[];
  onInputChange?: (val: string) => void; 
  placeholder?: string; 
  noOptionsText?: string;
  onClick?: () => void;
  isDirty?: boolean;
  link?: string;
}

export const BasicInfoListItem = ({ 
  title, text, color, inputType, inputValue, selectOptions, onSave, onInputChange, placeholder, noOptionsText, onClick,
  isDirty, link
}: BasicInfoListItemProps) => {
  const [editing, setEditing] = useState(false);
  // const itemControl = useRef<null | ReactElement>(null);

  const handleItemClick = () => {
    if (!editing) {
      setEditing(true);
      // (itemControl.current as unknown as HTMLElement)?.focus();
    }
  };

  const handleItemBlur = () => {
    setEditing(false);
  };

  const handleEnter: React.KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setEditing(false);
    }
  };

  return (
    <WidgetListItem onClick={handleItemClick} secondaryAction={
      isDirty ? <CabIcon Icon={BsDot} size="large" color={colors.black400} sx={{ marginTop: 3 }} />
        : (link && !editing && <Link to={link}><CabIcon Icon={IoOpenOutline} sx={{ marginTop: 3 }} /></Link>)
    }>
      <ListItemText
        primary={title}
        secondary={(
          <Box>
            <Box visibility={editing ? 'visible' : 'hidden'} height={editing ? undefined : 0}>
              {inputType === 'text' ? (
                <CabTextInput
                  // inputRef={ref}
                  // inputRef={itemControl}
                  onBlur={handleItemBlur}
                  onKeyDown={handleEnter}
                  overrides={{ autoFocus: true }}
                  value={inputValue}
                  onChange={e => onSave(e.target.value)}
                  fullWidth
                />
              ) : inputType === 'multiline' ? (
                <CabTextInput
                  // inputRef={ref}
                  // inputRef={itemControl}
                  onBlur={handleItemBlur}
                  onKeyDown={handleEnter}
                  overrides={{ autoFocus: true }}
                  value={inputValue}
                  onChange={e => onSave(e.target.value)}
                  fullWidth
                  multiline
                  minRows={2}
                  maxRows={2}
                />
              ) : inputType === 'autocomplete' ? (
                <CabAutocomplete
                  overrides={{ autoFocus: true, onBlur: handleItemBlur }}
                  onKeyDown={handleEnter}
                  // inputRef={itemControl}
                  // onBlur={handleItemBlur}
                  options={selectOptions?.map(option => ({
                    value: option.value,
                    label: option.label
                  })) || []}
                  noOptionsText={noOptionsText}
                  placeholder={placeholder}
                  onInputChange={onInputChange ? (v) => onInputChange(v) : undefined}
                  value={typeof inputValue !== 'string' ? inputValue : null}
                  onChange={onSave}
                  sx={{ width: '100%' }}
                />
              ) : inputType === 'select' ? (
                <CabDropdown
                  overrides={{
                    autoFocus: true,
                    onKeyDown: handleEnter,
                  }}
                  // inputRef={itemControl}
                  onBlur={handleItemBlur}
                  options={selectOptions?.map(option => ({
                    value: option.value,
                    label: option.color ? <CabToggleChip chipColor={option.color} label={option.label} selected />
                      : option.label
                  })) || []}
                  value={inputValue ?? ''}
                  onChange={e => onSave(e.target.value)}
                  sx={{ width: '100%' }}
                />
              ) : (
                Array.isArray(inputValue) && inputValue.every(v => typeof v === 'string') && (
                  <ListInput value={inputValue} onChange={onSave} onDone={handleItemBlur} />
                )
              )}
            </Box>
            {!editing && (
              <Box>
                {color
                  ? <CabToggleChip chipColor={color} label={text} selected />
                  : text || <Box sx={{ height: 30 }} component="span">&nbsp;</Box>
                }
              </Box>
            )}
          </Box>
        )}
        primaryTypographyProps={{ fontSize: 13, fontWeight: 'bolder', color: colors.black700 }}
        secondaryTypographyProps={editing
          ? { component: Box }
          : {
            fontWeight: 'bold', fontSize: 16, color: colors.black900, onClick,
            sx: { cursor: onClick ? 'pointer' : undefined }
          }
        }
      />
    </WidgetListItem>
  );
};

const ListInput = ({ value, onChange, onDone }: {
  value: string[];
  onChange: (v: string[]) => void;
  onDone: () => void;
}) => {
  const { control, handleSubmit } = useForm({ defaultValues: { emails: value.map(v => ({ value: v })) } });
  const { fields, append, remove } = useFieldArray({ control, name: 'emails' });

  const handleDone = (values: { emails: { value: string }[] }) => {
    onChange(values.emails.map(v => v.value));
    onDone();
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {fields.map((e, idx) => (
        <FormController
          rules={{
            required: true,
            validate: (v) => validateEmail(v.value) ? true : 'Invalid email'
          }}
          key={e.id}
          name={`emails.${idx}`}
          control={control}
          render={({ field: { ref, ...field }}) => (
            <Box display="flex" width="100%" alignItems="center">
              <CabTextInput
                inputRef={ref}
                size="xsmall"
                formControlSx={{ flex: 1 }}
                value={field.value.value}
                onChange={ev => field.onChange({ value: ev.target.value })}
              />
              <IconButton edge='end' aria-label='remove' disableRipple onClick={() => remove(idx)}>
                <CabIcon Icon={IoTrashOutline} alt='Remove' sx={{color: colors.black900 }} />
              </IconButton>
            </Box>
          )}
        />
      ))}
      <Box display="flex" width="100%" gap={1}>
        <CabButton
          icon={<CabIcon Icon={IoAdd} />}
          buttonType="tertiary"
          size="small"
          sx={{ flex: 1 }}
          onClick={() => append({ value: '' })}
        >
          Add New
        </CabButton>
        <CabButton size="small" sx={{ flex: 1 }} onClick={handleSubmit(handleDone)}>
          Done
        </CabButton>

      </Box>
    </Box>
  );
};
